const courseFiltersMixin = {
  data() {
    return {
      showCourseFiltersDialog: false,
      /**
       * @type {'all' | 'audio' | 'video'} MediaType
       */
      mediaType: 'all',
      maxTime: 0,
      filteredCourses: [],
    };
  },
  methods: {
    openCourseFiltersDialog() {
      this.showCourseFiltersDialog = true;
    },
    closeCourseFiltersDialog() {
      this.showCourseFiltersDialog = false;
    },
    setFilteredCourses(courses) {
      this.filteredCourses = courses;
    },
    setMediaType(mediaType) {
      this.mediaType = mediaType;
    },
    setMaxTime(maxTime) {
      this.maxTime = maxTime;
    },
    filterCourses(courses, showSnackBarMessage = true, closeDialogOnFinish = true) {
      this.filterCoursesByMediaType(courses);
      this.filterCoursesByMaxTime(this.filteredCourses);
      closeDialogOnFinish && this.closeCourseFiltersDialog();
      showSnackBarMessage && this.setSuccessSnackbarMessage('Courses successfully filtered');
    },
    resetCourses(courses) {
      this.filteredCourses = courses;
      this.setMediaType('all');
      this.setMaxTime(0);
      this.setSuccessSnackbarMessage('Filters successfully cleared');
    },
    filterCoursesByMediaType(courses) {
      if (this.mediaType === 'all') {
        this.filteredCourses = courses;
      } else if (this.mediaType === 'video') {
        if (courses instanceof Array) {
          this.filteredCourses = courses.filter(course => course.videoUrl);
        } else {
          this.filteredCourses = {};
          for (const id in courses) {
            const course = courses[id];
            if (course.videoUrl) {
              this.filteredCourses[id] = course;
            }
          }
        }
      } else {
        if (courses instanceof Array) {
          this.filteredCourses = courses.filter(course => !course.videoUrl);
        } else {
          this.filteredCourses = {};
          for (const id in courses) {
            const course = courses[id];
            if (!course.videoUrl) {
              this.filteredCourses[id] = course;
            }
          }
        }
      }
    },
    filterCoursesByMaxTime(courses) {
      if (this.maxTime === 0) {
        this.filteredCourses = courses;
      } else {
        if (courses instanceof Array) {
          this.filteredCourses = courses.filter(({ duration }) => duration <= this.maxTime);
        } else {
          this.filteredCourses = {};
          for (const id in courses) {
            const course = courses[id];
            if (course.duration <= this.maxTime) {
              this.filteredCourses[id] = course;
            }
          }
        }
      }
    },
  },
};

export default courseFiltersMixin;
