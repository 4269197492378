<template>
  <div class="mt-10">
    <!-- search input -->
    <v-row
      justify="space-between"
      no-gutters>
      <v-col
        class="d-flex"
        cols="8">
        <v-text-field
          id="search-input"
          prepend-inner-icon="mdi-magnify"
          hide-details
          filled
          rounded
          label="Search for an exercise"
          color="brandGrey1"
          dense
          clearable
          class="mr-5"
          clear-icon="mdi-close-circle-outline"
          v-model="searchInput">
        </v-text-field>
        <v-btn
          depressed
          color="primaryColor"
          rounded
          class="filter-btn px-3"
          @click="openCourseFiltersDialog">
          <v-img :src="require('@/assets/course/filtersIcon.png')"></v-img>
          <span class="text-uppercase">Filters</span>
        </v-btn>
      </v-col>
    </v-row>
    <!-- show courses based on keyword and filters-->
    <h2 class="section-title mt-8 mb-6">Search results</h2>
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="primaryColor">
    </v-progress-linear>
    <template v-else>
      <div
        v-if="noCoursesMatchSearch"
        class="empty-search">
        YOUR SEARCH '{{ searchInput }}' DID NOT MATCH ANY RESULTS
      </div>

      <div
        class="courses-grid-wrap"
        v-else>
        <CourseRowCard
          v-for="course in filteredCourses"
          :key="course.id"
          :course="course" />
      </div>
    </template>
    <course-filters-dialog
      :isDialogShown="showCourseFiltersDialog"
      :mediaType="mediaType"
      :maxTime="maxTime"
      @closeDialog="closeCourseFiltersDialog"
      @setMediaType="setMediaType"
      @setMaxTime="setMaxTime"
      @filterCourses="setFilterCourses(courses, searchInput, 'filter', true)"
      @clearAllFilters="setFilterCourses(courses, searchInput, 'clear')" />
  </div>
</template>

<script>
  import CoursesSearchMixin from '@/components/courses/courses-search/CoursesSearch.mixin.vue';
  export default {
    mixins: [CoursesSearchMixin],
  };
</script>

<style lang="scss" scoped>
  .filter-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    height: unset !important;
    .v-image {
      filter: brightness(0) invert(1);
    }
  }
  .empty-search {
    letter-spacing: 0.15rem;

    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: var(--v-brandGrey2-base);
  }
  ::v-deep {
    .v-label {
      opacity: 0;
    }
    #search-input {
      transform: translateY(-12px);
    }
  }
  .section-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
  }
  .courses-grid-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, 278px);
    gap: 16px;
  }
</style>
