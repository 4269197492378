<template>
  <component
    v-bind="[$props, $attrs]"
    v-on="$listeners"
    :is="component" />
</template>

<script>
  import screenSizeEnum from '@/enums/screenSize.js';
  import { mapGetters } from 'vuex';
  export default {
    computed: {
      ...mapGetters(['getScreenSize']),
      component() {
        switch (this.getScreenSize) {
          case screenSizeEnum.MOBILE:
            return () =>
              import('@/components/courses/course-filters-dialog/course-filters-dialog/CourseFiltersDialog.mobile.vue');
          case screenSizeEnum.DESKTOP:
            return () =>
              import(
                '@/components/courses/course-filters-dialog/course-filters-dialog/CourseFiltersDialog.desktop.vue'
              );
          default:
            return () =>
              import('@/components/courses/course-filters-dialog/course-filters-dialog/CourseFiltersDialog.mobile.vue');
        }
      },
    },
  };
</script>
