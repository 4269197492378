<script>
  import courseFiltersMixin from '@/mixins/courseFiltersMixin.js';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import CourseRowCard from '@/components/courses/course-row-card/CourseRowCard.vue';
  import CourseFiltersDialog from '@/components/courses/course-filters-dialog/course-filters-dialog/CourseFiltersDialog.vue';

  export default {
    mixins: [courseFiltersMixin],
    name: 'courses-search',
    data() {
      return {
        isLoading: false,
      };
    },
    props: {
      shouldOpenDialogBeforeSearchInit: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      CourseRowCard,
      CourseFiltersDialog,
    },
    watch: {
      courses(val) {
        this.setFilterCourses(val, this.searchInput);
      },
    },
    computed: {
      ...mapGetters('CoursesModule', ['courses']),
      ...mapGetters('searchModule', ['getSearchKeyword']),
      noCoursesMatchSearch() {
        return this.filteredCourses?.length < 1;
      },
      searchInput: {
        get() {
          return this.getSearchKeyword;
        },
        set(val) {
          this.setFilterCourses(this.courses, val);
          this.setSearchKeyword(val);
        },
      },
    },
    created() {
      if (this.shouldOpenDialogBeforeSearchInit) {
        this.openCourseFiltersDialog();
      }
      this.fetchCourses();
      this.setFilteredCourses(this.courses);
      this.setFilterCourses(this.courses, this.searchInput);
    },
    mounted() {
      this.focusOnSearchInput();
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage', 'setSuccessSnackbarMessage']),
      ...mapMutations('searchModule', ['setSearchKeyword']),
      ...mapActions('CoursesModule', ['fetchAll']),
      focusOnSearchInput() {
        const searchInput = document.getElementById('search-input');
        searchInput.focus();
      },
      async fetchCourses() {
        this.isLoading = true;
        try {
          await this.fetchAll();
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoading = false;
        }
      },
      filterBySearch(courses, input) {
        if (input === null) input = '';
        const searchRegExp = new RegExp(input, 'gi');
        this.filteredCourses = courses.filter(({ title }) => searchRegExp.test(title));
      },
      /**
       * @param {course} courses
       * @param {string} searchInput
       * @param {("filter" | "clear")} dialogAction
       */
      setFilterCourses(courses, searchInput, dialogAction = 'filter', showSnackBarMessage = false) {
        this.filterBySearch(courses, searchInput);

        if (dialogAction === 'filter') {
          this.filterCourses(this.filteredCourses, showSnackBarMessage);
        } else {
          this.resetCourses(this.filteredCourses);
        }
      },
    },
  };
</script>
